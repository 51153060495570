<template>
  <SchoolListsBaseForm :on-success="onSuccess" :readonly="readonly">
    <template #header>
      <span v-if="name"> - {{ name }}</span>
    </template>

    <template #default>
      <b-row>
        <b-col col-sm="6">
          <BaseInput label="Programme" rules="required">
            <SchoolSelect
              v-model="EstablishmentId"
              type="program"
              :disabled="readonly"
            >
              <el-option :value="null" label="Sélectionner un programme" />
            </SchoolSelect>
          </BaseInput>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="3">
          <BaseInput
            v-model="name"
            :readonly="readonly"
            label="Nom du sous-programme"
            rules="required"
          />
        </b-col>

        <b-col sm="3">
          <CurrencyInput
            v-model="depositAmount"
            :readonly="readonly"
            label="Montant du dépôt demandé"
          />
        </b-col>

        <b-col sm="3">
          <BaseInput label="Session" rules="required">
            <SchoolSessionSelect
              v-model="SessionId"
              :establishment-id="EstablishmentId"
              :list-id="schoolList.id"
              :readonly="readonly"
              label="Session"
            >
              <template #error="{ anyOptions }">
                <span
                  v-if="anyOptions == false"
                  class="invalid-feedback"
                  style="display: block"
                >
                  {{ $t('logicError.CANT_CREATE_LIST_NO_SESSION_FOUND') }}
                </span>
              </template>
            </SchoolSessionSelect>
          </BaseInput>
        </b-col>
        <b-col col-sm="6">
          <BaseInput
            v-model="numberOfStudents"
            type="number"
            :readonly="readonly"
            label="Nombre d'élèves"
            rules="required"
          />
        </b-col>
      </b-row>
    </template>

    <template v-if="schoolList.id" #categories>
      <ProgramCategoryForm v-if="readonly === false" />
      <ProgramCategoryList v-else />
    </template>
  </SchoolListsBaseForm>
</template>

<script>
import { BaseInput } from '@coop-zone/coop-zone-argon'
import { mapGetters, mapState } from 'vuex'
import ProgramCategoryForm from '@/components/SchoolLists/Program/ProgramCategoryForm'
import ProgramCategoryList from '@/components/SchoolLists/Program/ProgramCategoryList'
import CurrencyInput from '@/components/Inputs/CurrencyInput'
import SchoolListsBaseForm from '@/components/SchoolLists/SchoolListsBaseForm'
import SchoolSelect from '@/components/Inputs/SchoolSelect'
import SchoolSessionSelect from '@/components/Inputs/SchoolSessionSelect'

export default {
  components: {
    BaseInput,
    ProgramCategoryForm,
    ProgramCategoryList,
    CurrencyInput,
    SchoolListsBaseForm,
    SchoolSelect,
    SchoolSessionSelect,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('schoolEstablishments', ['schoolEstablishment']),
    ...mapGetters('schoolLists', ['schoolList']),
    name: {
      get() {
        return this.$store.state.schoolLists.name
      },
      set(name) {
        this.$store.commit('schoolLists/SET_NAME', name)
      },
    },
    numberOfStudents: {
      get() {
        return this.$store.state.schoolLists.numberOfStudents
      },
      set(name) {
        this.$store.commit('schoolLists/SET_NUMBER_OF_STUDENTS', name)
      },
    },
    depositAmount: {
      get() {
        return this.$store.state.schoolLists.depositAmount
      },
      set(depositAmount) {
        this.$store.commit('schoolLists/SET_DEPOSIT_AMOUNT', depositAmount)
      },
    },
    EstablishmentId: {
      get() {
        return this.$store.state.schoolLists.EstablishmentId
      },
      set(EstablishmentId) {
        this.$store.commit('schoolLists/SET_ESTABLISHMENT_ID', EstablishmentId)
      },
    },
    SessionId: {
      get() {
        return this.$store.state.schoolLists.SessionId
      },
      set(SessionId) {
        this.$store.commit('schoolLists/SET_SESSION_ID', SessionId)
      },
    },
  },
}
</script>
