<template>
  <SchoolListsBaseForm :on-success="onSuccess" :readonly="readonly">
    <template #header>
      <span v-if="name"> - {{ name }}</span>
      <span v-if="option"> - {{ option }}</span>
    </template>

    <template #default>
      <b-row>
        <b-col col-sm="6">
          <BaseInput label="Établissement" rules="required">
            <SchoolSelect
              v-model="EstablishmentId"
              type="grade"
              :disabled="readonly"
            >
              <el-option :value="null" />
            </SchoolSelect>
          </BaseInput>
        </b-col>
        <b-col col-sm="6">
          <BaseInput
            v-model="numberOfStudents"
            type="number"
            :readonly="readonly"
            label="Nombre d'élèves"
            rules="required"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col col-sm="4">
          <BaseInput
            v-model="name"
            :readonly="readonly"
            data-cy="input-schoolsupplylist-name"
            label="Niveau scolaire"
            rules="required"
          />
        </b-col>
        <b-col col-sm="4">
          <BaseInput
            v-model="option"
            :readonly="readonly"
            data-cy="input-schoolsupplylist-option"
            label="Option (facultatif)"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6">
          <BaseInput label="Session" rules="required">
            <SchoolSessionSelect
              v-model="SessionId"
              :establishment-id="EstablishmentId"
              :list-id="schoolList.id"
              :readonly="readonly"
              label="Session"
            >
              <template #error="{ anyOptions }">
                <span
                  v-if="anyOptions == false"
                  class="invalid-feedback"
                  style="display: block"
                >
                  {{ $t('logicError.CANT_CREATE_LIST_NO_SESSION_FOUND') }}
                </span>
              </template>
            </SchoolSessionSelect>
          </BaseInput>
        </b-col>
        <b-col sm="6">
          <BaseInput
            v-model="notes"
            type="textarea"
            label="Notes destinées aux administrateurs (facultatif)"
          />
        </b-col>
      </b-row>
    </template>

    <template v-if="schoolList.id" #categories>
      <GradeCategoryForm v-if="readonly === false" />
      <GradeCategoryList v-else />
    </template>
  </SchoolListsBaseForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseInput } from '@coop-zone/coop-zone-argon'
import GradeCategoryForm from '@/components/SchoolLists/Grade/GradeCategoryForm'
import GradeCategoryList from '@/components/SchoolLists/Grade/GradeCategoryList'
import SchoolListsBaseForm from '@/components/SchoolLists/SchoolListsBaseForm'
import SchoolSelect from '@/components/Inputs/SchoolSelect'
import SchoolSessionSelect from '@/components/Inputs/SchoolSessionSelect'

export default {
  components: {
    BaseInput,
    GradeCategoryForm,
    GradeCategoryList,
    SchoolListsBaseForm,
    SchoolSelect,
    SchoolSessionSelect,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('schoolLists', ['schoolList']),
    name: {
      get() {
        return this.$store.state.schoolLists.name
      },
      set(name) {
        this.$store.commit('schoolLists/SET_NAME', name)
      },
    },
    numberOfStudents: {
      get() {
        return this.$store.state.schoolLists.numberOfStudents
      },
      set(name) {
        this.$store.commit('schoolLists/SET_NUMBER_OF_STUDENTS', name)
      },
    },
    option: {
      get() {
        return this.$store.state.schoolLists.option
      },
      set(option) {
        this.$store.commit('schoolLists/SET_OPTION', option)
      },
    },
    notes: {
      get() {
        return this.$store.state.schoolLists.notes
      },
      set(notes) {
        this.$store.commit('schoolLists/SET_NOTES', notes)
      },
    },
    EstablishmentId: {
      get() {
        return this.$store.state.schoolLists.EstablishmentId
      },
      set(EstablishmentId) {
        this.$store.commit('schoolLists/SET_ESTABLISHMENT_ID', EstablishmentId)
      },
    },
    SessionId: {
      get() {
        return this.$store.state.schoolLists.SessionId
      },
      set(SessionId) {
        this.$store.commit('schoolLists/SET_SESSION_ID', SessionId)
      },
    },
  },
}
</script>
