<template>
  <SchoolListsBaseForm :on-success="onSuccess" :readonly="readonly">
    <template #header>
      <span v-if="name"> - {{ name }}</span>
      <span v-if="courseCode"> - {{ courseCode }}</span>
    </template>

    <template #default>
      <b-row>
        <b-col col-sm="6">
          <BaseInput label="Établissement" rules="required">
            <SchoolSelect
              v-model="EstablishmentId"
              :disabled="readonly"
              type="course"
              @input="establishmentChange"
            >
              <el-option :value="null" />
            </SchoolSelect>
          </BaseInput>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="pl-lg-4">
            <hr class="my-4" />
            <h3 class="heading-small text-muted mb-4">Cours</h3>
            <b-row>
              <b-col md="6" lg="4">
                <BaseInput
                  v-model="name"
                  :readonly="readonly"
                  label="Titre du cours"
                  rules="required"
                />
              </b-col>
              <b-col md="6" lg="4">
                <BaseInput
                  v-model="courseCode"
                  :readonly="readonly"
                  label="Code du cours"
                  rules="required"
                />
              </b-col>
              <b-col md="6" lg="4">
                <BaseInput
                  v-model="nrcCode"
                  :readonly="readonly"
                  label="Code NRC"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" lg="4">
                <BaseInput v-if="sectionsByType" label="Sections (facultatif)">
                  <el-select
                    v-model="Sections"
                    placeholder="Sélectionner une section"
                    :disabled="readonly || !EstablishmentId"
                    value-key="id"
                    multiple
                  >
                    <el-option
                      v-for="section in sectionsByType"
                      :key="section.id"
                      :label="section.name"
                      :value="section"
                    />
                  </el-select>
                </BaseInput>
              </b-col>
              <b-col md="6" lg="4">
                <BaseInput label="Session" rules="required">
                  <SchoolSessionSelect
                    v-model="SessionId"
                    :establishment-id="EstablishmentId"
                    :list-id="schoolList.id"
                    :readonly="readonly"
                    label="Session"
                  >
                    <template #error="{ anyOptions }">
                      <span
                        v-if="anyOptions == false"
                        class="invalid-feedback"
                        style="display: block"
                      >
                        {{ $t('logicError.CANT_CREATE_LIST_NO_SESSION_FOUND') }}
                      </span>
                    </template>
                  </SchoolSessionSelect>
                </BaseInput>
              </b-col>
              <b-col md="6" lg="4">
                <BaseInput
                  v-model="numberOfStudents"
                  type="number"
                  :readonly="readonly"
                  label="Nombre d'élèves"
                  rules="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <BaseInput
                  v-model="notes"
                  type="textarea"
                  label="Notes destinées aux administrateurs (facultatif)"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <hr class="my-4" />
                <h3 class="heading-small text-muted mb-4">Professeur</h3>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <BaseInput
                  v-model="teacherName"
                  :readonly="readonly"
                  prepend-icon="fas fa-user"
                  label="Nom "
                  rules="required"
                />
              </b-col>
              <b-col>
                <BaseInput
                  v-model="teacherPhone"
                  :readonly="readonly"
                  prepend-icon="fas fa-phone"
                  label="Téléphone (facultatif)"
                />
              </b-col>
              <b-col>
                <BaseInput
                  v-model="teacherEmail"
                  :readonly="readonly"
                  prepend-icon="fas fa-envelope"
                  type="email"
                  rules="email"
                  label="Courriel (facultatif)"
                />
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </template>

    <template v-if="schoolList.id" #categories>
      <CourseCategoryForm v-if="readonly === false" />
      <CourseCategoryList v-else />
    </template>
  </SchoolListsBaseForm>
</template>

<script>
import { BaseInput } from '@coop-zone/coop-zone-argon'
import { mapGetters, mapState } from 'vuex'
import CourseCategoryForm from '@/components/SchoolLists/Course/CourseCategoryForm'
import CourseCategoryList from '@/components/SchoolLists/Course/CourseCategoryList'
import SchoolListsBaseForm from '@/components/SchoolLists/SchoolListsBaseForm'
import SchoolSelect from '@/components/Inputs/SchoolSelect'
import SchoolSessionSelect from '@/components/Inputs/SchoolSessionSelect'

export default {
  components: {
    BaseInput,
    CourseCategoryForm,
    CourseCategoryList,
    SchoolListsBaseForm,
    SchoolSelect,
    SchoolSessionSelect,
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('accounts', ['account']),
    ...mapGetters('schoolEstablishments', ['schoolEstablishment']),
    ...mapGetters('schoolLists', ['schoolList']),
    ...mapGetters('schoolLists/sections', {
      sectionsByType: 'getSectionsByType',
    }),
    name: {
      get() {
        return this.$store.state.schoolLists.name
      },
      set(name) {
        this.$store.commit('schoolLists/SET_NAME', name)
      },
    },
    numberOfStudents: {
      get() {
        return this.$store.state.schoolLists.numberOfStudents
      },
      set(numberOfStudents) {
        this.$store.commit('schoolLists/SET_NUMBER_OF_STUDENTS', numberOfStudents)
      },
    },
    courseCode: {
      get() {
        return this.$store.state.schoolLists.courseCode
      },
      set(courseCode) {
        this.$store.commit('schoolLists/SET_COURSE_CODE', courseCode)
      },
    },
    nrcCode: {
      get() {
        return this.$store.state.schoolLists.nrcCode
      },
      set(nrcCode) {
        this.$store.commit('schoolLists/SET_NRC_CODE', nrcCode)
      },
    },
    notes: {
      get() {
        return this.$store.state.schoolLists.notes
      },
      set(notes) {
        this.$store.commit('schoolLists/SET_NOTES', notes)
      },
    },
    teacherName: {
      get() {
        return this.$store.state.schoolLists.teacherName
      },
      set(teacherName) {
        this.$store.commit('schoolLists/SET_TEACHER_NAME', teacherName)
      },
    },
    teacherPhone: {
      get() {
        return this.$store.state.schoolLists.teacherPhone
      },
      set(teacherPhone) {
        this.$store.commit('schoolLists/SET_TEACHER_PHONE', teacherPhone)
      },
    },
    teacherEmail: {
      get() {
        return this.$store.state.schoolLists.teacherEmail
      },
      set(teacherEmail) {
        this.$store.commit('schoolLists/SET_TEACHER_EMAIL', teacherEmail)
      },
    },
    Sections: {
      get() {
        return this.$store.state.schoolLists.Sections
      },
      set(sections) {
        this.$store.commit('schoolLists/SET_SECTIONS', sections)
      },
    },
    EstablishmentId: {
      get() {
        return this.$store.state.schoolLists.EstablishmentId
      },
      set(EstablishmentId) {
        this.$store.commit('schoolLists/SET_ESTABLISHMENT_ID', EstablishmentId)
      },
    },
    SessionId: {
      get() {
        return this.$store.state.schoolLists.SessionId
      },
      set(SessionId) {
        this.$store.commit('schoolLists/SET_SESSION_ID', SessionId)
      },
    },
  },
  methods: {
    async establishmentChange() {
      if (!this.EstablishmentId) return
      await this.$store.dispatch(
        'schoolEstablishments/getSchoolEstablishment',
        this.EstablishmentId
      )
      await this.$store.dispatch(
        'schoolLists/sections/getSectionsByType',
        this.schoolEstablishment.type
      )
      this.handelSectionsModel()
    },
    handelSectionsModel() {
      if (!this.Sections.length) return
      this.Sections.forEach((object, index) => {
        const foundOne = this.sectionsByType.filter(
          (section) => section.id === object.id
        )
        if (foundOne.length) return
        this.Sections.splice(index, 1)
      })
    },
  },
}
</script>
