<template>
  <p>
    Si aucun résultat n’apparaît, le volume est peut-être épuisé. Veuillez
    contacter
    <a class="text-info" href="mailto:eps@coopzone.ca">
      eps@coopzone.ca
    </a>
    afin que les vérifications nécessaires soient faites.
  </p>
</template>
<script>
export default {}
</script>
<style lang="sass" scoped>
a
  text-decoration: underline
</style>
